@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  .header {
    font-size: 40px;
    margin-bottom: 30px;
    font-weight: 700;
    color: $green-2;
  }

  .description {
    width: 40%;
    margin-bottom: 30px;
    color: $blue-3;
    font-size: 26px;
    font-weight: 400;
    @media screen and (max-width: $breakpoint-lg) {
      width: 80%;
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 90%;
    }
  }

  .children {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
