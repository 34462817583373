.container {
  display: flex;
  margin: 30px 0;
  justify-content: space-around;
  width: 90%;
  flex-wrap: wrap;
  row-gap: 30px;
  .logo {
    height: 120px;
  }
}
