@import "./variables.scss";

@mixin light-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
@mixin content-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s linear;
  &:hover {
    box-shadow: 5px 5px 20px 6px rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.2s linear;
  }
}

@mixin remove-shadow-viewport {
  @media screen and (max-width: $breakpoint-lg) {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

@mixin width-viewport {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
