@import "../../styles/variables.scss";

button.container {
  font-weight: 700;
  font-size: 16px;
  text-transform: inherit;
  border-radius: 37px;

  &.containedButton {
    &.primaryColor {
      background: $blue-2;

      &:hover {
        background: $gray-3;
      }
    }

    &.secondaryColor {
      background: $green-1;

      &:hover {
        background: $gray-3;
      }
    }

    &.tertiaryColor {
      background: $green-3;

      &:hover {
        background: $gray-3;
      }
    }

    &.disabled {
      background: $gray-7;
      color: $gray-6;
    }
  }

  &.textButton {
    color: $blue-3;
    background: $gray-1;

    &:hover {
      text-decoration: underline $green-2 3px;
      text-underline-offset: 5px;
    }

    &.disabled {
      color: $gray-6;
    }
  }
}

.icon {
  width: fit-content;
}
