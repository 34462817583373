@import "../../../../styles/variables.scss";

.container {
  display: flex;
  justify-content: space-around;
  width: 80%;

  @media screen and (max-width: $breakpoint-xxl) {
    width: 90%;
  }
  @media screen and (max-width: $breakpoint-xl) {
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-lg) {
    display: grid;
    grid-template-rows: minmax(200px, 1fr) minmax(200px, 1fr);
    row-gap: 40px;
    width: 90%;
  }
}
