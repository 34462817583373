@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  padding: 40px;
  border-radius: 30px;
  @include content-shadow;

  &:hover {
    cursor: pointer;
  }

  .title {
    margin-bottom: 20px;
    max-height: 90px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      max-height: 150px;
    }

    .description {
      text-align: left;
      color: $blue-3;
      width: 62%;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-xlg) {
  .container {
    padding: 20px;
    .title {
      max-height: 60px;
    }
  }
}
