@import "../../../../styles/variables.scss";

.container {
  display: flex;
  width: 95%;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (min-width: $breakpoint-xxxl) {
    width: 80%;
  }
  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
  }
}
