/*gray*/
$gray-1: #ffffff;
$gray-2: #dedede;
$gray-3: #44494d;
$gray-4: #333333;
$gray-5: #000000;
$gray-6: #0000004d;
$gray-7: #0000001a;

/*blue*/
$blue-1: #1b7b8b;
$blue-2: #007297;
$blue-3: #044075;

/*green*/
$green-1: #9dc900;
$green-2: #8ec14b;
$green-3: #529b6d;
$green-4: #358c7c;

/* breakpoints */
$breakpoint-mini: 320px;
$breakpoint-xs: 400px;
$breakpoint-sm: 600px;
$breakpoint-md: 720px;
$breakpoint-lg: 800px;
$breakpoint-xlg: 960px;
$breakpoint-xl: 1024px;
$breakpoint-xxl: 1280px;
$breakpoint-xxxl: 1480px;
