@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 150px;
  margin-bottom: 50px;
  @include width-viewport;
  row-gap: 30px;

  .gif{
    width: 500px;
  }
}

@media screen and (max-width: $breakpoint-xxl) {
  .container {
    width: 95%;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
  .gif {
    width: 400px;
  }
}
}

@media screen and (max-width: $breakpoint-xs) {
  .container {
  .gif {
    width: 350px;
  }
}
}

