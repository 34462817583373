@import "../../styles/variables.scss";

.container {
  font-size: 24px;
  color: $gray-4;
  font-weight: 700;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .position {
    color: $blue-2;
    margin-top: 28px;
  }
  .description {
    font-weight: 200;
  }
  .photo {
    max-height: 250px;
  }
  .linkedInButton {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border: $blue-2 solid 3px;
    border-radius: 20.5px;
    color: $blue-2;

    &:hover {
      color: $gray-1;
      background: $blue-2;
    }
  }
}
